<template>
  <div>
    <h2 class="mb-4" v-text="$t('project.academy.users.competitorData')" />
    <v-row>
      <v-col cols="12" sm="6" class="py-1 px-3">
        <v-text-field
          v-model="form.firstName" outlined dense :label="$t('common.name')"
          :error-messages="getFieldErrors('firstName')" @blur="$v.form.firstName.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="6" class="py-1 px-3">
        <v-text-field
          v-model="form.lastName" outlined dense :label="$t('user.fields.lastName')"
          :error-messages="getFieldErrors('lastName')" @blur="$v.form.lastName.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="6" class="py-1 px-3">
        <birthday-field
          v-model="form.birthdate" outlined dense :label="$t('user.fields.birthdate')"
          :error-messages="getFieldErrors('birthdate')" @blur="$v.form.birthdate.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="6" class="py-1 px-3">
        <v-select
          v-model="form.country" :items="countryItems" outlined dense
          append-icon="mdi-chevron-down" hide-details="auto" :label="$t('user.fields.country')"
          :error-messages="getFieldErrors('country')" @blur="$v.form.country.$touch()"
        />
      </v-col>
      <v-col v-if="!dontHaveEmail" cols="12" sm="6" class="py-1 px-3">
        <v-text-field
          v-model="form.email" :disabled="!!email"
          outlined dense :label="$t('user.fields.email')"
          :error-messages="getFieldErrors('email')" @blur="$v.form.country.$touch()"
        />
      </v-col>
      <v-col v-if="terms.length" cols="12" sm="6" class="py-1 px-3">
        <file-field
          v-model="form.waiver" accept="application/pdf,image/*" :label="$t('common.waiver')"
          :max-size="1000000" :error-messages="getFieldErrors('waiver')"
        />
      </v-col>
    </v-row>
    <edit-user-form ref="subForm" :main-title="$t('common.formData')" />
    <div class="d-flex justify-center">
      <v-btn color="primary" min-width="12rem" @click="save" v-text="$t('common.save')" />
    </div>
  </div>
</template>
<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import i18nCountries from '@/utils/mixins/i18nCountries'
import formUtils from '@/utils/mixins/formUtils'
import dataMixin from './dataMixin'

export default {
  name: 'NoEmail',
  components: {
    BirthdayField: () => import('@/components/formFields/BirthdayField'),
  },
  mixins: [dataMixin, i18nCountries, formUtils],
  props: { email: { type: String, default: null }, dontHaveEmail: { type: Boolean, required: true } },
  data: () => ({
    form: { firstName: null, lastName: null, birthdate: null, country: null, email: null, waiver: null },
  }),
  validations() {
    return {
      form: {
        waiver: { required: requiredIf(() => this.terms.length) },
        firstName: { required },
        lastName: { required },
        birthdate: { required },
        country: { required },
        email: { required: requiredIf(() => !this.dontHaveEmail) },
      },
    }
  },
  created() {
    this.form.email = this.email
  },
  methods: {
    async save() {
      if (this.$refs.subForm.form) this.$refs.subForm.$v.form.$touch()
      if (!this.isFormValid() || (this.$refs.subForm.form && !this.$refs.subForm.isFormValid())) {
        this.scrollToFirstError()
        return
      }
      const [year, month, day] = this.form.birthdate.split('-')
      const data = (({ email, waiver, ...rest }) => ({
        ...rest,
        birthdate: { year: +year, month: +month, day: +day },
      }))(this.form)
      await this.runAsync(async () => {
        const userId = await this.$store.dispatch('user/createWithBackend', { data, email: this.form.email?.toLowerCase() })
        if (userId) await this.createEnrollment({ userId, setPassword: true })
      })
      this.$emit('close-dialog')
    },
  },
}
</script>
